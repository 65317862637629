import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import AuthContext from "../../contexts/AuthContext";
import axiosInstance from "../../utils/axiosInstance";
import { GRANT_TYPE, REFRESH_TOKEN } from "../../config/constants";
import API_ENDPOINTS from "../../config/apiEndpoints";

function LoginModal({ show, onHide }) {
  const { login } = useContext(AuthContext);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate(); // Corrected hook name

  const handleLogin = async () => {
    const loginData = {
      clientId: clientId,
      clientSecret: clientSecret,
      grantType: GRANT_TYPE,
      refreshToken: REFRESH_TOKEN,
    };

    try {
      const response = await axiosInstance.post(API_ENDPOINTS.AUTH.LOGIN, loginData);

      setClientId("");
      setClientSecret("");

      if(response.status===200){
        const{AccessToken} = response.data;
        if(AccessToken){
          console.log("Login successful:",AccessToken);
          login(AccessToken);
          onHide();
          navigate("/dashboard");
        } else{
          setErrorMessage("Login failed: Invalid credentials.");
        }
      }
      
    } catch (error) {
      console.log("Error logging in:", error);
      setErrorMessage("Login failed: Server error.");
    }
  };

  // Function to handle the Enter key press
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      handleLogin(); // Trigger login on Enter
    }
  };
  const handleClose = () => {
    setErrorMessage('');  // Clear the error message
    onHide();
  };
  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: "24px" }}>Login Form</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onKeyDown={handleKeyDown}>
          <Form.Group className="mb-3" controlId="loginModal.userId">
            <Form.Label style={{ fontSize: "18px" }}>User Id:</Form.Label>
            <Form.Control
              type="text"
              autoFocus
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
              style={{ fontSize: "16px" }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="loginModal.password">
            <Form.Label style={{ fontSize: "18px" }}>Password:</Form.Label>
            <Form.Control
              type="password"
              value={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
              style={{ fontSize: "16px" }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1, textAlign: "left" }}>
          {errorMessage && (
            <p className="text-danger" style={{ fontSize: "16px", margin: 0 }}>
              {errorMessage}
            </p>
          )}
        </div>
        <Button
          className="btn btn-primary"
          onClick={handleLogin}
          style={{ fontSize: "16px", padding: "10px 20px" }}
        >
          Login
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LoginModal;
