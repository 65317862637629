const BASE_URL = process.env.REACT_APP_API_BASE_URL;

if(!BASE_URL){
  console.error('REACT_APP_API_BASE_URL is not defined!');
}

export const API_ENDPOINTS = {
  PORTFOLIO: {
    GET_ALL: `${BASE_URL}/api/AboutMe`,
    GET_BY_ID: (id) => `${BASE_URL}/api/AboutMe/${id}`,
    CREATE: `${BASE_URL}/api/AboutMe`,
    UPDATE: (id) => `${BASE_URL}/api/AboutMe/${id}`,
    DELETE: (id) => `${BASE_URL}/api/AboutMe/${id}`,
  },
  AUTH: {
    LOGIN: `${BASE_URL}/v1/oauth/token`,
  }
};

export default API_ENDPOINTS;
